import {Api} from "@/shared/services/api";
import copyTo from "@/shared/utils/CopyTo";
import Person from "@/shared/model/person";
import Address from "@/shared/model/Address";
import ProductPrice from "@/shared/model/productPrice";
import Product from "@/shared/model/product";
import {OrgStructureCluster} from "@/shared/model/orgStructureCluster";
import Account from "@/shared/model/Account";
import PersonDetails from "@/shared/model/personDetails";

export class ApiOrganisationStructure extends Api {
  async loadPerson(
    id: string | undefined,
    ownerPersonId?: string
  ): Promise<
    { person: Person; addresses: Address[]; details: PersonDetails,hasExportBlankProduct:boolean|undefined } | undefined
  > {
    if (id) {
      const request = {
        id: id,
        ownerPersonId: ownerPersonId,
      };
      const resp = await this.post("/PersonRequest", request);
      if (resp.successful) {
        const person = new Person();
        copyTo(resp.person, person);
        if (resp.clusterTypes) {
          person.clusterTypes = resp.clusterTypes;
        }

        const addresses: Address[] = [];
        resp.addresses.map((a: Address) => {
          const address = new Address();
          copyTo(a, address);
          addresses.push(address);
        });
        return {person, addresses, details: new PersonDetails(resp.details),hasExportBlankProduct:resp.hasExportBlankProduct};
      }
      return undefined;
    }
  }

  async loadProductPrice(
    key: string,
    id: string,
    activeOnly: boolean,
    situation?: string
  ): Promise<
    { key: string; prices: ProductPrice[]; products: Product[] } | undefined
  > {
    if (id) {
      const request = {
        id: id,
        productPrice: true,
        product: true,
        activeOnly: activeOnly,
        situation: situation
      };
      const resp = await this.post("/PersonDetailsRequest", request);
      if (resp.successful) {
        const arr: [] = resp.productPrices;
        const prices = arr.map((value) => {
          const price = new ProductPrice();
          copyTo(value, price);
          return price;
        });
        const arrProduct: [] = resp.products;
        const products = arrProduct.map((value) => {
          const obj = new Product();
          copyTo(value, obj);
          return obj;
        });
        return {key: key, prices: prices, products: products};
      }
    }
  }

  async list(
    ownerPersonId: string,
    type: OrgStructureCluster,
  ): Promise<Person[] | undefined> {
    try {
      const request = {
        ownerPersonId: ownerPersonId,
        typeId: type,
        filter: "",
      };
      const resp = await this.post("/PersonListRequest", request);
      if (resp.successful && resp.items) {
        return resp.items;
      }
    } finally {
    }
  }

  async listAddress(
    ownerPersonId: string,
    type?: string,
    filter?: string
  ): Promise<{ Person: Person; Address: Address }[] | undefined> {
    try {
      const request = {
        ownerPersonId: ownerPersonId,
        typeId: type ?? "activation",
        filter: filter,
      };
      const resp = await this.post("/PersonAddressListRequest", request);
      if (resp.successful && resp.items) {
        return resp.items.map((t: any) => {
          return {Person: t.person, Address: t.address};
        });
      }
    } finally {
    }
  }

  async update(
    ownerPersonId: string,
    person?: Person,
    address?: Address
  ): Promise<string | undefined> {
    const request = {
      ownerPersonId: ownerPersonId,
      person: person,
      address: address,
      details: undefined,
    };
    const resp = await this.post("/PersonUpdateRequest", request);
    if (resp.successful && resp.id) {
      return resp.id;
    }
  }

  async deleteAddress(
    id: string
  ): Promise<boolean | undefined> {
    const request = {
      id: id
    };
    const resp = await this.post("/AddressDeleteRequest", request);
    return resp.successful;
  }


  async updateDetailsCluster(
    ownerPersonId: string,
    id: string,
    addClusterTypes?: string[],
    removeClusterTypes?: string[]
  ): Promise<boolean | undefined> {
    const request = {
      ownerPersonId: ownerPersonId,
      personId: id,
      addClusterTypes: addClusterTypes,
      removeClusterTypes: removeClusterTypes,
      details: undefined,
    };
    const resp = await this.post("/PersonDetailsUpdateRequest", request);
    return resp.successful;
  }

  async updateDetailsPrice(
    ownerPersonId?: string,
    id?: string,
    addProductPriceInfo?: ProductPrice,
    editProductPriceInfo?: ProductPrice,
    removeId?: string
  ): Promise<boolean | undefined> {
    const request = {
      ownerPersonId: ownerPersonId,
      personId: id,
      addProductPriceInfo: addProductPriceInfo,
      editProductPriceInfo: editProductPriceInfo,
      removeProductPriceInfo: removeId,
      details: undefined,
    };
    const resp = await this.post("/PersonDetailsUpdateRequest", request);
    return resp.successful;
  }

  async updateDetails(
    ownerPersonId?: string,
    id?: string,
    details?: any
  ): Promise<boolean | undefined> {
    const request = {
      ownerPersonId: ownerPersonId,
      personId: id,
      details: details,
    };
    const resp = await this.post("/PersonDetailsUpdateRequest", request);
    return resp.successful;
  }

  async loadAccount(id: string): Promise<Account | undefined> {
    if (id) {
      const request = {
        personId: id,
      };
      const resp = await this.post("/AccountRequest", request);
      if (resp.successful) {
        const account = new Account();
        copyTo(resp, account);
        return account;
      }
      return undefined;
    }
  }

  async checkAccount(loginName: string): Promise<boolean | undefined> {
    try {
      const request = {
        loginName: loginName,
      };
      const resp = await this.post("/AccountCheckRequest", request);
      if (resp.successful) {
        return resp.value;
      }
      return undefined;
    } finally {
    }
  }

  async updateAccount(loginName: string, email: string, twoFactorAuthentication: boolean): Promise<boolean | undefined> {
    const request = {
      loginName: loginName,
      email: email,
      twoFactorAuthentication: twoFactorAuthentication
    };
    const resp = await this.post("/AccountUpdateRequest", request);
    return resp.successful;
  }


  async createAccount(
    loginName: string,
    personId: string,
    actionPersonId: string,
    email: string
  ): Promise<boolean | undefined> {
    try {
      const request = {
        loginName: loginName,
        personId: personId,
        actionPersonId: actionPersonId,
        email: email
      };
      const resp = await this.post("/AccountInsertRequest", request);
      if (resp.successful) {
        return true;
      }
      return undefined;
    } finally {
    }
  }

  async resetPassword(loginName: string, password: string): Promise<boolean | undefined> {
    try {
      const request = {
        loginName: loginName,
        oldPassword: "",
        newPassword: password,
        newPasswordConfirm: password,
      };
      const resp = await this.post("/AccountChangePasswordRequest", request);
      if (resp.successful) {
        return true;
      }
      return undefined;
    } finally {
    }
  }

  async delete(loginName: string): Promise<boolean | undefined> {
    try {
      const request = {
        loginName: loginName,
      };
      const resp = await this.post("/AccountDeleteRequest", request);
      if (resp.successful) {
        return true;
      }
      return undefined;
    } finally {
    }
  }


  async accountChangeActions(
    personId: string,
    actions: string[]
  ): Promise<boolean | undefined> {
    const request = {
      personId: personId,
      actions: actions,
    };
    const resp = await this.post("/AccountChangeActionsRequest", request);
    if (resp.successful) {
      return true;
    }
    return undefined;
  }
}

const apiOrganisationStructure = new ApiOrganisationStructure();

export default apiOrganisationStructure;
